// $fa-font-path: "../../static/webfonts";
// @import "./fontawesome/fontawesome.scss";
// @import "./fontawesome/solid.scss";
// @import "./fontawesome/brands.scss";

.footer-social-container {
  display: flex;
  flex-direction: row;
}

@media screen and (max-width: 768px) {
  .footer-social-container {
    justify-content: center;
  }

  .alert-dismissible {
    width: 80vw;
  }
}

.alert-dismissible .close {
  transform: translateY(-5px);
}
.alert-dismissible .close:hover {
  color: white;
}
